export async function backendFetch(url, options) {
  const lang = options.lang ?? navigator.language

  let requestURL
  if (typeof url == 'string' && !url.startsWith('http')) {
    requestURL = new URL((globalThis.WAASABI_BACKEND ?? '')+url)
  } else {
    requestURL = new URL(url)
  }

  const requestOptions = {
    headers: {
      'Accept-Language': lang,
    },
  }

  let json = false;
  if (typeof options.body == 'object') {
    requestOptions.method = 'post'
    requestOptions.headers = requestOptions.headers ?? {}
    requestOptions.headers['Content-Type'] = 'application/json'
    requestOptions.body = JSON.stringify(options.body)
    json = true
  }

  return await fetch(requestURL, requestOptions).then(r => json ? r.json() : r.text())
}
