// All settings

const ConfigData=await fetch('/assets/src/pages/ticketing/lib/config.json').then(r=>r.json());
export const configuration = ConfigData

//import TicketsJson from './tickets.json'
const TicketsData=await fetch('/assets/src/pages/ticketing/lib/tickets.json').then(r=>r.json());

// start: null -> not open yet
// end: null   -> no specified end date
// pwyc sales start at 'latest timezone' 00:00:00 on the Monday of the week of the event
// pwyc sales end 12 hours before the start date of the event
export const sale = new Map(TicketsData.map(t => {
  t.start = t.start && new Date(t.start)
  t.end = t.end && new Date(t.end)
  t.available = t.start && t.start < Date.now()
  t.expired = t.end && t.end < Date.now()

  return [ t.id, t ]
}))

// Are PWYC ticket available right now?
export const pwycTicket = () => {
  const tickets = Array.from(sale.values())

  const t = tickets.find(i => i.type === 'pwyc' && i.available && !i.expired)
    ?? tickets.find(i => i.type === 'pwyc')

  return t
}
export const pwyc = {
  get id() {
    const t = pwycTicket()
    if (t) return t.id
  },
  // pwyc-type ticket with the current date between its start/end-sales period
  get available() {
    const t = pwycTicket()
    const now = new Date()
    return t.start <= now && now <= t.end
  },
  get event() {
    const id = this.id
    const ev = EventsData.find(e => id.startsWith(e.label))
    if (ev) return ev.name
  }
}
/*
export const typeMap = new Map(ticket.types.map(p => [ p.type, p ]))
export const priceMap => sale
export const availabilityMap = new Map(availability.map(p => [ p.ticketid, p ]))
export const subeventMap = new Map(subevents.map(p => [ p.label, p ]))
*/

//import PricingJson from './pricing.json'
const PricingData=await fetch('/assets/src/pages/ticketing/lib/pricing.json').then(r=>r.json());

// Calculated using OECD purchasing power parity (2020 data from ~50 countries)
// https://data.oecd.org/conversion/purchasing-power-parities-ppp.htm
export const pricing = new Map(PricingData.map(o => {
  o.pmax = configuration.p_max_factor*o.pmin;
  return [ o.currency.toLowerCase(), o ]
}))


//import EventsJson from './pricing.json'
const EventsData=await fetch('/assets/src/pages/ticketing/lib/events.json').then(r=>r.json());

export const events = new Map(EventsData.map(e => {
  return [ e.label, e ]
}))

// List event data that is valid for this event
export const validEvents = (ticketId) => {
  const ticketClass = (ticketId ?? '').replace(/-[^-]+$/, '')
  return EventsData.filter(e => e.label.startsWith(ticketClass))
}


//
import StripeCurrencies from './stripe.js';
export const currencies = StripeCurrencies


// Shown on the Stripe receipt
const TicketTypesData=await fetch('/assets/src/pages/ticketing/lib/tickettypes.json').then(r=>r.json());

export const types = new Map(TicketTypesData.map(t => {
  // Allow filling in event details from configuration
  Object.entries(configuration).forEach(([k,v]) => {
    const rx = new RegExp(k.toUpperCase(), 'g')
    t.name = t.name.replace(rx, v)
    t.description = t.description.replace(rx, v)
  })

  return [ t.type, t ]
}))
