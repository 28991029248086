import { DateTime, IANAZone, LocalZone, Interval } from 'luxon';


/* Cache the IANA timezone list */
let IANA_TZ = fetch('/assets/js/IANA_TZ.json')
  .then(r => r.json())
  .then(r => IANA_TZ = r)
  .catch(e => {
    console.error('Failed to load timezone list!', e);
    IANA_TZ = [];
  });


/* Returns a list of all known IANA timezone names */
export async function timezones() {
  return IANA_TZ;
}

/* Returns the current Zone, preconfigured or auto-detected */
export function preferredZone() {
  return window.localStorage.getItem('preferred_timezone') || deviceZone();
}
export function setPreferredZone(zone) {
  return window.localStorage.setItem('preferred_timezone', zone);
}

/* Returns the detected timezone of the current browser */ 
export function deviceZone() {
  return new LocalZone().name;
}

/* Returns the zone name and UTC offset (right now) */
export function zoneInfo(zone) {
  const now = DateTime.local().setZone(zone);
  return `${now.zone.name} (UTC${now.zone.formatOffset(now, 'narrow')})`;
}

/* Display formatted date with timezone info */
export function displayDate(date = new Date().toISOString(), zone = LocalZone().name) {
  const now = DateTime.fromISO(date).setZone(zone);
  return now.toFormat(`yyyy LLLL d, cccc ZZZZ`);
}

/* Display formatted date with timezone info */
export function displayTimeAndDate(date = new Date().toISOString(), zone = LocalZone().name) {
  const now = DateTime.fromISO(date).setZone(zone);
  return now.toFormat(`HH:mm - LLLL d, cccc ZZZZ`);
}

/* Display formatted date with timezone info */
export function displayInterval(interval = new Date().toISOString(), zone = LocalZone().name) {
  return Interval.fromISO(interval, { zone }).toFormat('HH:mm');
}

/* Longform display of time interval */
export function displayIntervalLong(interval = new Date().toISOString(), zone = LocalZone().name) {
  const iv = Interval.fromISO(interval, { zone });
  return iv.toFormat('MMM d, HH:mm') + iv.start.toFormat(` ZZZZZ ('UTC'Z)`);
}

/* Returns the sorted options for the timezone select component */
export async function timezoneSelector() {
  const tz = await timezones();

  /* Generate time zone list */
  const now = DateTime.local();
  const tzlist = tz.map(zone => {
    const iana = new IANAZone(zone);
    return ({
      zone,
      order: iana.offset(now),
      offset: iana.formatOffset(now, 'short'), 
      time: now.setZone(zone).toFormat('HH:mm'),
    });
  });

  /* Order by offset, then by zone name */
  tzlist.sort((a,b) => {
    if (a.order === b.order) return a.zone.localeCompare(b.zone);
    return a.order-b.order;
  });


  const opts = [];
  let lastOffset = null;
  for (const { zone, time, offset } of tzlist) {
    if (offset !== lastOffset) {
      lastOffset = offset;
      opts.push(`<optgroup label="⏱ ${time} (UTC${offset})">`);
    }

    opts.push(`<option>${zone}</option>`);
  };


  return opts.join('');
}
