import dialogPolyfill from 'dialog-polyfill';
import {
  timezoneSelector,
  preferredZone, setPreferredZone,
  zoneInfo,
  displayTimeAndDate, displayInterval, displayIntervalLong,
} from './timezone.js';
import './tz.js';

import './backdrop.js';

import './pages/assign.js';

// Ticketing UI component include
import './pages/ticketing/lib/getaccess.js'

(async () => {
  setTZ(preferredZone());

  setupToggles();
})();

function setTZ(zone) {
  let tzDependent = Array.from(document.querySelectorAll('[data-tz]'));

  for (const tzElem of tzDependent) {
    const type = tzElem.dataset.tz;
    const dt = tzElem.getAttribute('datetime');

    /* Update with current zone */
    if (type === 'zone') {
      tzElem.textContent = zoneInfo(zone);
    } else if (type === 't-start-time') {
      tzElem.textContent = displayTimeAndDate(dt, zone);
    } else if (type === 't-duration') {
      tzElem.textContent = displayInterval(dt, zone);
    } else if (type === 't-start-and-duration') {
      tzElem.textContent = displayIntervalLong(dt, zone);
    } else if (type === 'change') {
      if (createTZDialog()) {
        tzElem.addEventListener('click', changeTZ);
      }
    }
  }

  console.log('Set timezone to: ', zone);
}
function changeTZ(event) {
  const dialog = getTZDialog();
  dialog.showModal();
}
function getTZDialog() {
  return document.querySelector('dialog.tzdialog');
}
async function createTZDialog() {
  // Don't create if already exists
  if (getTZDialog()) return false;

  let options = await timezoneSelector();

  document.body.insertAdjacentHTML('beforeend',`
    <dialog class="tzdialog fixed">
      <h4 class="tzdialog__title">Select your timezone</h4>
      <div class="tzdialog__body">
        <p><label>We detected your timezone as: <select>${options}</select></p>
        <p>All times on the schedule are listed in this timezone, you can select a different timezone above.</p>
      </div>
      <button type="dialog">Confirm</button>
    </div>`);

  return setTimeout(() => {
    const dialog = getTZDialog();
    const select = dialog.querySelector('select');
    const confirm = dialog.querySelector('button');
    
    if (!dialog) {
      return console.error('Failed to create timezone dialog');
    }
    dialogPolyfill.registerDialog(dialog);

    if (select) {
      const zone = preferredZone();
      document.querySelector('dialog select').value = zone;
    }

    select.addEventListener('change', (e) => confirm.value = e.target.value);
    confirm.addEventListener('click', (e) => {
      dialog.returnValue = e.target.value || select.value;
      dialog.close();
    });
    dialog.addEventListener('close', () => {
      if (dialog.returnValue) {
        const zone = dialog.returnValue;
        setTZ(zone);
        setPreferredZone(zone);
      }
    });
  }, 1);
}

/* Makes collapse toggles operational */
function setupToggles() {
  document.body.addEventListener('click', e => {
    let t = e.target;
    if (!t.dataset.action) return;

    let p = t.parentNode;
    while (p) {
      if (p.dataset.is) {
        p.dataset.is = p.dataset.is=='collapsed' ? 'expanded' : 'collapsed';
        return;
      }
      p = p.parentNode;
    }
  });
}