import { html, render } from 'lit-html';
import { validEvents } from './ticketing/lib/ticketing.js'
const WAASABI_BACKEND = process.env.WAASABI_BACKEND;

const PAGE_SELECTOR = '#assign_passes';

const ticketEvents = (type) => {
  const events = validEvents(type)
  const list = events.map(e => html`<li><a href="${e.link}">${e.name}</a></li>`)

  return html`<ul>${list}</ul>`
}

// Assign - ticket information
const ticketInfo = ({ticket_id, name, included_passes, type, attendees}) => html`
  <ul class="ticket_details">
    <li><strong>Ticket ID:</strong> ${ticket_id}</li>
    <li><strong>Name on ticket:</strong> ${name}</li>
    <li><strong>Included passes:</strong> ${included_passes} (${attendees.length} assigned)</li>
    <li><strong>Validity:</strong> ${ticketEvents(type)}</li>
  </ul>`;

const ticketStatus = ({status}) => {
  if (status == 'assigned' || status == 'notified') {
    return html`<div class="status ${status}">
      Status: the Attendee <strong>has not yet</strong> confirmed their attendance.</div>`;

  } else if (status == 'confirmed') {
    return html`<div class="status ${status}">
      Status: the Attendee has <strong>already confirmed</strong> their attendance.</div>`;

  }

  return html`<div class="status ${status}">Status: <strong>Unassigned</strong></div>`;
};

const ticketAssign = (p) => html`
  <fieldset id=${'p'+p.nr} data-nr=${p.nr} data-id=${p.id}>
    <legend>Access Pass #${p.nr<10?'0':''}${p.nr}</legend>
    <input type="hidden" name="replace_id" value=${p.id ?? ''}>
    <input name="email" type="email" placeholder="E-mail address" required value=${p.email||''}>
    <input name="name" type="text" placeholder="Name (optional)" value=${p.name||''}>
    <button value=${'assign-'+p.nr} @click=${requestAssign}>Assign</button>
    <i>
      ${ticketStatus(p)}
    </i>
  </fieldset>
`;

const ticketReassign = (p) => html`
  <fieldset id=${'p'+p.nr} data-nr=${p.nr} data-id=${p.id}>
    <legend>Access Pass #${p.nr<10?'0':''}${p.nr}</legend>
    <input name="email" type="email" placeholder="E-mail address" required value=${p.email||''} disabled>
    <input name="name" type="text" placeholder="Name (optional)" value=${p.name||''} disabled>
    <button name="reassign" value=${p.nr} @click=${changeAssigned}>Reassign</button>
    <i>
      ${ticketStatus(p)}
    </i>
  </fieldset>
`;

const assignMoreBtn = (p) => {
  const passes = p.included_passes;
  const attendees = p.attendees.length;
  const remain = passes - attendees;

  const handler = Object.assign({}, p, { handleEvent: assignNew });

  return html`
    <button name="assignmore" @click=${handler}>Assign new (${remain} passes remain)</button>
  `;
};

const ticketList = (list) => {
  return list.map( (p,i) =>
    ticketReassign(Object.assign(p, {nr: i+1}))
  );
};

// Gets pass information from the window/location
function getCurrentPass() {
  return Array.from(
    new URL(window.location).searchParams
  ).reduce(
    (o, [k,v]) => Object.assign(o,{[k]:v})
  , {});
}

// Gets pass information using the ticket details in window/location
async function getCurrentPassInfo() {
  const pass = getCurrentPass();

  const passinfo = await fetch(
    WAASABI_BACKEND+`/ticketing/attendees/${pass.p}?key=`+pass.k
  ).then(r => r.json());

  return passinfo;
}

//
// Gets pass information from the window/location
async function getCurrentPassAttendee(id) {
  const passinfo = await getCurrentPassInfo();

  // Returns an attendee or undefined
  return passinfo.attendees.filter(a => a.id === id)[0];
}

async function assignNew(e) {
  const btn = e.target;
  e.preventDefault(); // prevent submitting the form

  let frag = document.createDocumentFragment();

  // Prefill pass with ticket info for single-pass tickets
  if (this.attendees.length === 0 && this.included_passes === 1) {
    const { name, email } = this
    render(ticketAssign({ name, email, nr: this.attendees.length+1 }), frag);
  } else {
    render(ticketAssign({nr: this.attendees.length+1 }), frag);
  }
  formFields().insertBefore(frag, btn);

  // Remove assign button
  btn.hidden = true;
}

async function changeAssigned(e) {
  const btn = e.target;
  e.preventDefault(); // prevent submitting the form

  // Parent fieldset
  const fs = btn.parentNode;

  // Parent's nr
  const nr = parseInt(fs.dataset.nr, 10);
  // Attendee id
  const id = parseInt(fs.dataset.id, 10);
  // Get the attendee details
  const attendee = await getCurrentPassAttendee(id);

  // Replace fieldset with an editable one
  let frag = document.createDocumentFragment();
  render(ticketAssign(Object.assign({ nr }, attendee)), frag);
  fs.replaceWith(frag);
}

async function requestAssign(e) {
  const btn = e.target;
  e.preventDefault(); // prevent submitting the form

  // Parent fieldset & nr
  const fs = btn.parentNode;
  const nr = parseInt(fs.dataset.nr, 10);

  // Collect input data
  const props = Array.from(fs.querySelectorAll('input,button')).reduce(
    (obj, {name,value}) => !!name ? Object.assign(obj, { [name]: value }) : obj
  , {});
  btn.disabled = true;

  const pass = getCurrentPass();
  const resp = await fetch(
    WAASABI_BACKEND+`/ticketing/attendees/${pass.p}?key=`+pass.k,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(props)
    }
  ).then(r => r.json());

  if (resp.error) {
    btn.disabled = false;
    return alert(resp.error.message||'Email address invalid or already registered!');
  }
  if (resp.message) {
    alert(resp.message);
  }

  // Replace fieldset with assigned one
  let frag = document.createDocumentFragment();
  render(ticketReassign(Object.assign({ nr }, resp)), frag);
  fs.replaceWith(frag);

  // Replace assign more button
  const passinfo = await getCurrentPassInfo();
  let morebtn = assignMoreBtn(passinfo);
  if (morebtn) {
    frag = document.createDocumentFragment();
    render(morebtn, frag);
    document.querySelector('button[name=assignmore]').replaceWith(frag);
  }

}

function formFields() {
  return document.querySelector('.registration__form .form_fields');
}

async function load() {
  const passinfo = await getCurrentPassInfo();

  const { attendees } = passinfo;

  // Render the template to the document
  render(ticketInfo(passinfo), document.querySelector('.ticket_info'));

  /*
  // Empty attendees list
  if (!attendees.length) {
    render(ticketAssign({ nr:1 }), frag);
    formFields().appendChild(frag);
    return;
  }
*/

  let frag = document.createDocumentFragment();
  render(ticketList(attendees), frag);
  formFields().appendChild(frag);

  // Assign more button?
  let morebtn = assignMoreBtn(passinfo);
  if (morebtn) {
    frag = document.createDocumentFragment();
    render(morebtn, frag);
    formFields().appendChild(frag);
  }
}

if (document.querySelector(PAGE_SELECTOR)) {
  load().catch(e => console.error(e));
};