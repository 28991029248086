// Stripe supported currencies, ~130: https://stripe.com/docs/currencies
// Currency information: https://en.wikipedia.org/wiki/List_of_circulating_currencies

const StripeCurrencies = [
  [ "AED", "United Arab Emirates dirham", "د.إ" ],
  [ "AFN", "Afghan afghani", "؋" ],
  [ "ALL", "Albanian lek", "L" ],
  [ "AMD", "Armenian dram", "֏" ],
  [ "ANG", "Netherlands Antillean guilder", "ƒ" ],
  [ "AOA", "Angolan kwanza", "Kz" ],
  [ "ARS", "Argentine peso", "$" ],
  [ "AUD", "Australian dollar", "$" ],
  [ "AWG", "Aruban florin", "ƒ" ],
  [ "AZN", "Azerbaijani manat", "₼" ],
  [ "BAM", "Bosnia and Herzegovina convertible mark", "KM" ],
  [ "BBD", "Barbadian dollar", "$" ],
  [ "BDT", "Bangladeshi taka", "৳" ],
  [ "BGN", "Bulgarian lev", "лв." ],
  [ "BIF", "Burundian franc", "Fr", "ZeroDecimal" ],
  [ "BMD", "Bermudian dollar", "$" ],
  [ "BND", "Brunei dollar", "$" ],
  [ "BOB", "Bolivian boliviano", "Bs." ],
  [ "BRL", "Brazilian real", "R$" ],
  [ "BSD", "Bahamian dollar", "$" ],
  [ "BWP", "Botswana pula", "P" ],
  [ "BYN", "Belarusian ruble", "Br" ],
  [ "BZD", "Belize dollar", "$" ],
  [ "CAD", "Canadian dollar", "$" ],
  [ "CDF", "Congolese franc", "Fr" ],
  [ "CHF", "Swiss franc", "Fr." ],
  [ "CLP", "Chilean peso", "$", "ZeroDecimal" ],
  [ "CNY", "Chinese yuan", "元" ],
  [ "COP", "Colombian peso", "$" ],
  [ "CRC", "Costa Rican colón", "₡" ],
  [ "CVE", "Cape Verdean escudo", "Esc" ],
  [ "CZK", "Czech koruna", "Kč" ],
  [ "DJF", "Djiboutian franc", "Fr", "ZeroDecimal" ],
  [ "DKK", "Danish krone", "kr" ],
  [ "DOP", "Dominican peso", "RD$" ],
  [ "DZD", "Algerian dinar", "د.ج" ],
  [ "EGP", "Egyptian pound", "ج.م" ],
  [ "ETB", "Ethiopian birr", "Br" ],
  [ "EUR", "Euro", "€" ],
  [ "FJD", "Fijian dollar", "$" ],
  [ "FKP", "Falkland Islands pound", "£" ],
  [ "GBP", "British pound", "£" ],
  [ "GEL", "Georgian lari", "₾" ],
  [ "GIP", "Gibraltar pound", "£" ],
  [ "GMD", "Gambian dalasi", "D" ],
  [ "GNF", "Guinean franc", "Fr", "ZeroDecimal" ],
  [ "GTQ", "Guatemalan quetzal", "Q" ],
  [ "GYD", "Guyanese dollar", "$" ],
  [ "HKD", "Hong Kong dollar", "$" ],
  [ "HNL", "Honduran lempira", "L" ],
  [ "HRK", "Croatian kuna", "kn" ],
  [ "HTG", "Haitian gourde", "G" ],
  [ "HUF", "Hungarian forint", "Ft" ],
  [ "IDR", "Indonesian rupiah", "Rp" ],
  [ "ILS", "Israeli new shekel", "₪" ],
  [ "INR", "Indian rupee", "₹" ],
  [ "ISK", "Icelandic króna", "kr" ],
  [ "JMD", "Jamaican dollar", "$" ],
  [ "JPY", "Japanese yen", "円", "ZeroDecimal" ],
  [ "KES", "Kenyan shilling", "Sh" ],
  [ "KGS", "Kyrgyzstani som", "с" ],
  [ "KHR", "Cambodian riel", "៛" ],
  [ "KMF", "Comorian franc", "Fr", "ZeroDecimal" ],
  [ "KRW", "South Korean won", "₩", "ZeroDecimal" ],
  [ "KYD", "Cayman Islands dollar", "$" ],
  [ "KZT", "Kazakhstani tenge", "₸" ],
  [ "LAK", "Lao kip", "₭" ],
  [ "LBP", "Lebanese pound", "ل.ل" ],
  [ "LKR", "Sri Lankan rupee", "Rs" ],
  [ "LRD", "Liberian dollar", "$" ],
  [ "LSL", "Lesotho loti", "L" ],
  [ "MAD", "Moroccan dirham", "د.م." ],
  [ "MDL", "Moldovan leu", "L" ],
  [ "MGA", "Malagasy ariary", "Ar", "ZeroDecimal" ],
  [ "MKD", "Macedonian denar", "ден" ],
  [ "MMK", "Burmese kyat", "Ks" ],
  [ "MNT", "Mongolian tögrög", "₮" ],
  [ "MOP", "Macanese pataca", "MOP$" ],
  // [ "MRO", null ], // Not listed by Wikipedia
  /// Mauritanian ouguiya -- https://fxtop.com/c/en/MRU
  /// Currency MRO doesn't have legal tender anymore, it was replaced by currency MRU since 2018-01-01.
  [ "MUR", "Mauritian rupee", "₨" ],
  [ "MVR", "Maldivian rufiyaa", ".ރ" ],
  [ "MWK", "Malawian kwacha", "MK" ],
  [ "MXN", "Mexican peso", "$" ],
  [ "MYR", "Malaysian ringgit", "RM" ],
  [ "MZN", "Mozambican metical", "MT" ],
  [ "NAD", "Namibian dollar", "$" ],
  [ "NGN", "Nigerian naira", "₦" ],
  [ "NIO", "Nicaraguan córdoba", "C$" ],
  [ "NOK", "Norwegian krone", "kr" ],
  [ "NPR", "Nepalese rupee", "रू" ],
  [ "NZD", "New Zealand dollar", "$" ],
  [ "PAB", "Panamanian balboa", "B/." ],
  [ "PEN", "Peruvian sol", "S/." ],
  [ "PGK", "Papua New Guinean kina", "K" ],
  [ "PHP", "Philippine peso", "₱" ],
  [ "PKR", "Pakistani rupee", "₨" ],
  [ "PLN", "Polish złoty", "zł" ],
  [ "PYG", "Paraguayan guaraní", "₲", "ZeroDecimal" ],
  [ "QAR", "Qatari riyal", "ر.ق" ],
  [ "RON", "Romanian leu", "lei" ],
  [ "RSD", "Serbian dinar", "дин." ],
  [ "RUB", "Russian ruble", "₽" ],
  [ "RWF", "Rwandan franc", "Fr", "ZeroDecimal" ],
  [ "SAR", "Saudi riyal", "﷼" ],
  [ "SBD", "Solomon Islands dollar", "$" ],
  [ "SCR", "Seychellois rupee", "₨" ],
  [ "SEK", "Swedish krona", "kr" ],
  [ "SGD", "Singapore dollar", "$" ],
  [ "SHP", "Saint Helena pound", "£" ],
  [ "SLL", "Sierra Leonean leone", "Le" ],
  [ "SOS", "Somali shilling", "Sh" ],
  [ "SRD", "Surinamese dollar", "$" ],
  // [ "STD", null ], // Not listed by Wikipedia
  /// São Tomé dobra -- https://fxtop.com/c/en/STD
  /// Currency STD doesn't have legal tender anymore, it was replaced by currency STN since 2018-01-01.
  [ "SZL", "Swazi lilangeni", "L" ],
  [ "THB", "Thai baht", "฿" ],
  [ "TJS", "Tajikistani somoni", "с." ],
  [ "TOP", "Tongan paʻanga[P]", "T$" ],
  [ "TRY", "Turkish lira", "₺" ],
  [ "TTD", "Trinidad and Tobago dollar", "$" ],
  [ "TWD", "New Taiwan dollar", "$" ],
  [ "TZS", "Tanzanian shilling", "Sh" ],
  [ "UAH", "Ukrainian hryvnia", "₴" ],
  [ "UGX", "Ugandan shilling", "Sh", "ZeroDecimal" ],
  [ "USD", "United States dollar", "$" ],
  [ "UYU", "Uruguayan peso", "$" ],
  [ "UZS", "Uzbekistani soʻm", "Sʻ" ],
  [ "VND", "Vietnamese đồng", "₫", "ZeroDecimal" ],
  [ "VUV", "Vanuatu vatu", "Vt", "ZeroDecimal" ],
  [ "WST", "Samoan tālā", "T" ],
  [ "XAF", "Central African CFA franc", "Fr", "ZeroDecimal" ],
  [ "XCD", "Eastern Caribbean dollar", "$" ],
  [ "XOF", "West African CFA franc", "Fr", "ZeroDecimal" ],
  [ "XPF", "CFP franc", "₣", "ZeroDecimal" ],
  [ "YER", "Yemeni rial", "﷼" ],
  [ "ZAR", "South African rand", "R" ],
  [ "ZMW", "Zambian kwacha", "ZK" ]
]

const list = Object.freeze(
  StripeCurrencies.map(([id,name,sign,zd]) => ({ id, name, sign, zd: !!zd }))
)
const map = new Map(list.map(o => [ o.id.toLowerCase(), o ]))

Object.defineProperty(map, 'isZeroDecimal', {
  value: (curr) => !!map.get(curr?.toLowerCase())?.zd
})

// Override .get()/.has() to ignore currency case
const _proto = Object.getPrototypeOf(map)
Object.defineProperty(map, 'get', {
  value: (curr) => _proto.get.call(map, curr?.toLowerCase()),
})
Object.defineProperty(map, 'has', {
  value: (curr) => _proto.has.call(map, curr?.toLowerCase()),
})

Object.defineProperty(map, 'list', {
  value: list,
  enumerable: true
})

export default map
