function fade() {
  const el = document.getElementById('notify')
  if (el) el.classList.add('fade')
}

function remove() {
  const el = document.getElementById('notify')
  if (el) el.remove()
}

export async function notify(text) {
  remove()

  document.body.insertAdjacentHTML('beforeend', `<div id=notify>${text}</div>`)

  setTimeout(fade, 10)
  setTimeout(remove, 6000)
}
