const BACKDROP_SELECTOR = '.rustfest_backdrop';

if (document.querySelector(BACKDROP_SELECTOR)) {
  window.addEventListener('scroll', (e) => {
    // By the time we scrolled to nes, twinkles should disappear
    const scroll = e.target.scrollingElement.scrollTop;
    const target = document.querySelector('main').offsetTop + document.querySelector('#news').offsetTop;
    const st = Math.max( 0, 1 - scroll / target);
    const el = document.querySelector(BACKDROP_SELECTOR);
    if (el) el.style="opacity: "+st;
  });
}
