import { createApp } from './petite-vue.es-map.js'

import { configuration, sale, types, pricing, currencies, events, pwyc } from './ticketing.js'

import VerifyEmail from './email-verification.js'
import { backendFetch } from './backend.js'
import { notify } from './notify.js'


// Mark loaded
window._waasabi = Object.assign(window._waasabi ?? {}, { ticketing: true })

const ttype = (t) => types.has(t) ? t : sale.get(t)?.type


createApp({
  $delimiters: ['${', '}'],

  /* Email verification component */
  VerifyEmail,

  /* Which subform is open? */
  // Values: reminder, suggest (also claim?)
  subform: false,


  /* Suggest another currency */
  suggestCurrency: '',
  async suggestSubmit(params) {
    const { email, code } = params
    const currency = this.suggestCurrency

    if (!currency) {
      // TODO: proper edge case handling?
      console.error('No suggestion specified.')
    } else {
      await backendFetch('/request/currency', {
        body: { email, code, currency }
      })
      // TODO: yay!
      //console.log('Received.')
      notify('Your request has been received.')
    }
    this.subform = false
  },

  /* Get notification for tickets going live */
  reminderId: '',
  async reminderSubmit(params) {
    const { email, code } = params
    const ticketid = this.reminderId

    await backendFetch('/request/notification', {
      body: { email, code, notification_type: 'ticketsales', ticketid }
    })

    // TODO: yay!
    //console.log('Subscribed.')
    notify('We will email you when the tickets become available.')

    this.subform = false
  },

  eventtitle: configuration.event_title,
  eventlabel: configuration.event_label,
  pricing: Object.fromEntries(pricing.entries()),
  fmtPricing(ticketid, o = { long: false }) {
    const type = ttype(ticketid)
    const currentPricing = pricing.get(this.currency)
    const pwyc = (type === 'pwyc')

    let price
    if (pwyc) {
      price = `${currentPricing.pmin*configuration.p_max_factor}`
    } else {
      price = String(currentPricing[type])
    }

    if (price.length > 3) price = [price.slice(0,-3),price.slice(-3)].join(',')

    if (o.long) return `${price}\u202f${this.currency.toUpperCase()}`

    const sign = this.CCY
    return `${pwyc?'0\u2013':''}${sign==='$'?sign:''}${price}\u202f${sign !=='$'?sign:''}`
  },
  sale: Object.fromEntries(sale.entries()),

  selected_ticket: '',

  get currencyList() { return currencies.list },
  get typesList() { return Array.from(types.values()) },
  get eventsList() { return Array.from(events.values()) },

  ownprice: '',
  get pmin() {
    return this.ownprice/(this.pricedata?.pmin ?? 0)
  },
  get ticketprice() {
    return (this.tickettype==='pwyc' ? Number(this.ownprice) : this.pricedata[this.tickettype])
  },
  get pricedata() {
    return pricing.get(this.currency)
  },

  get pwyc() {
    return (this.tickettype==='pwyc')
  },

  onSale(ticketid) {
    const now = new Date()
    const ticket = sale.get(ticketid)
    console.log(ticket)
    return ticket.start <= now && (ticket.end === null || ticket.end >= now)
  },

  get MAX_PRICEFACTOR() {
    return configuration.p_max_factor
  },
  get CCY() { return currencies.get(this.currency).sign },
  get CCLABEL() { return currencies.get(this.currency).id },

  priceUpdate() {
    if (this.tickettype==='pwyc') {
      this.subevent = events.keys().next().value//EventsData[0].label;
      this.ownprice = ''
    } else {
      this.subevent = 'default'
      this.ownprice = this.pricedata[this.tickettype]
    }
  },
  submittable: false,
  checkAmount(fix = false) {
    if (this.ownprice === '') return this.submittable = false

    const int = Math.floor(this.ownprice)
    if (int === 0) return this.submittable = true

    const min = this.pricedata.pmin
    const max = configuration.p_max_factor*this.pricedata.pmin

    if (this.ownprice !== int) {
      this.submittable = false
      if (!fix) return this.submittable
      this.ownprice=int
    }

    if (this.ownprice < min) {
      this.submittable = false
      if (!fix) return this.submittable
      this.ownprice=min
    }
    if (this.ownprice > max) {
      this.submittable = false
      if (!fix) return this.submittable
      this.ownprice=max
    }

    // Actually valid
    this.submittable = true
    return this.submittable
  },

  buyButton(el) {
    if (!el || typeof el !== 'object') return {}

    const ticketid = ('target' in el ? el.target : el).value
    if (!ticketid) return {}

    const pwyc = ticketid.includes('pwyc')
    const type = ttype(ticketid)

    const subform = ('target' in el ? el.target : el).dataset.sf

    return { ticketid, pwyc, type, subform }
  },
  buyButtonText(el) {
    const { ticketid, pwyc: isPwyc, subform: isSubform, type } = this.buyButton(el);

    if (isPwyc) {
      // If no tickets available now let users subscribe for a notification
      if (!pwyc.available) {
        return 'Get a reminder'
      }

      if (isSubform) {
        if (this.ownprice === 0) return 'Get a free ticket'
        if (this.ownprice) return `Buy a ${pwyc.event} ticket for ${this.ownprice} ${this.CCY}`
        return 'Choose your price...'
      }

      return 'Name your price'
    }

    return `Purchase ${type} pass for ${this.fmtPricing(type, {long:true})}`
  },
  buyButtonClick(e) {
    const { ticketid, pwyc:isPwyc, subform:isSubform } = this.buyButton(e);

    // Buy pay what you can ticket
    if (isPwyc) {
      // If they are not available show the notification form
      if (!pwyc.available) {
        e.preventDefault()
        this.subform = 'reminder'
        this.reminderId = ticketid
        setTimeout(() => window.location.href = "#reminder", 0)
        return
      }

      // This is the main form, show subform for PWYC ticket details
      if (!isSubform) {
        e.preventDefault()
        this.subform = 'pwycform'
        this.pwycformId = ticketid
        setTimeout(() => window.location.href = "#pwycform", 0)
        return
      }

      // Opted for a free ticket, redirect to the free ticket form
      if (this.ownprice === 0) {
        e.preventDefault()
        setTimeout(() => window.location.href = "/free-registration/", 0)
      }

      // Otherwise, just let the form go through and submit to the checkout page
    }
  },

  currency: 'eur', // TODO: store user preference in localStorage?
  tickettype: types.keys().next().value,
  subevent: events.keys().next().value,
}).mount()
