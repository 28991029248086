// based on https://github.com/moment/luxon/blob/master/src/zones/systemZone.js
const tz = () => {
  let { locale, timeZone } = new Intl.DateTimeFormat().resolvedOptions();
  let offset = new Date().getTimezoneOffset() / -60;
  return { locale, timeZone, offset }
}

window._tzInfo = tz

window.addEventListener(
  'DOMContentLoaded',
  () => Array.from(
    document.querySelectorAll('#yestz')
  ).forEach(e => e.value = JSON.stringify(_tzInfo())))