import { backendFetch } from "./backend.js"


export default function VerifyEmail (props) {
  return {
    $template: `
      <span v-if="!requested" class="text-and-button">
        <input type="email"
          placeholder="email address"
          v-model="emailAddress"
          @input="emailValid=$el.checkValidity()"
          @keypress="request"
        >
        <button @click.prevent="request" v-bind="{ disabled: !email }">Verify</button>
      </span>
      <span v-if="requested" class="text-and-button">
        <input type="tel" maxlength="6" autofocus
          placeholder="000000"
          v-model="code"
          v-effect="if(requested) $el.focus()"
          @keypress="submit"
        >
        <button @click.prevent="submit">${props.submitText ?? 'Submit'}</button>
      </span>
    `,

    emailAddress: '',
    emailValid: false,
    requested: false,

    get email() {
      if (props.email) return props.email
      return this.emailValid ? this.emailAddress : ''
    },

    code: '',
    async request(e) {
      if (e?.type === 'keypress' && e.key !== 'Enter') return
      e.preventDefault()

      const email = this.email
      if (!email) return

      if (e?.target) e.target.disabled = true

      const result= await verify(email, { reason: props.reason })
      this.requested = true
    },
    submit(e) {
      if (e?.type === 'keypress' && e.key !== 'Enter') return
      const cbo = {
        email: String(this.email),
        code: String(this.code),
      }

      this.reset(e)
      props.cb(cbo)
    },
    reset(e) {
      this.emailAddress = ''
      this.emailValid = this.requested = false
    }

  }
}

export async function verify(email, options) {
  const reason = options.reason ?? 'unspecified'

  return await backendFetch(`/email-verification/request`, {
    body: {
      email,
      reason,
    }
  })
}
